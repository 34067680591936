<script>
import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getBrokerageApi} from '@/api/brokerage'

import defaultAvatar from "@/assets/images/users/avatar-9.jpg"



/**
 * User grid component
 */
export default {
  page: {
    title: "User Grid",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Staff",
      items: [
        {
          text: "Brokerage",
          href: "/",
        },
        {
          text: "Staff",
          active: true,
        },
      ],
      userGridData: [
      ],
      showModal: false,
      contact: {
        name: "",
        designation: "",
        email: "",
        file: "",
      },
      submitted: false,
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {

    delete_staff(e) {
     
      let  data={id : e.id}
      getBrokerageApi().staffApi.delete_staff(data).then((res) => {
        if (res.errCode == 0) {
          this.userGridData =  this.userGridData.filter((u) => u.id != data.id)
        } else {
          this.$alertify.error("Remove Staff Failed "+ res.errCode);
        }
      })
    },

    edit_staff(staff) {
      this.$router.push({name :'brokerage-staff-edit', params: {staff : staff} })
    }
  },

  created() {
    getBrokerageApi().staffApi.list().then((res)=>{
      res.data.map((o) =>{
        this.userGridData.push({
          id: o.id,
          profile: o.avatar?o.avatar:defaultAvatar,
          designation: o.first_name +' ' +o.last_name,
          name: o.first_name +' ' +o.last_name,
          first_name : o.first_name,
          last_name : o.last_name,
          avatar : o.avatar,
          mobile :o.mobile,
          email : o.email,
          roles :  o.roles? o.roles : [],
          offices: o.offices?o.offices: []
        })
      })  

    })

    
  },
  validations: {
    contact: {
      name: { required },
      designation: { required },
      email: { required },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Staff List
                    <span class="text-muted fw-normal ms-2">({{ userGridData.length }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->
              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                 
                  <div>
                    <a
                      href="/brokerage/staff/edit"
                      class="btn btn-light"
                      data-bs-toggle="modal"
                     
                      ><i class="uil uil-plus me-1"></i> Add New</a
                    >
                  </div>
                 
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->


            <div class="row">
              <div
                class="col-xl-4 col-sm-6"
                v-for="(user, index) in userGridData"
                :key="index"
              >
                <div class="card border shadow-none">
                  <div class="card-body p-4">
                    <div class="d-flex align-items-start">
                      <div class="flex-shrink-0 avatar rounded-circle me-3">
                        <img
                          :src="user.profile"
                          alt=""
                          class="img-fluid rounded-circle"
                        />
                      </div>
                      <div class="flex-grow-1 overflow-hidden">
                        <h5 class="font-size-15 mb-1 text-truncate">
                          <router-link to="/pages/profile" class="text-dark">{{
                            user.name
                          }}</router-link>
                        </h5>
                        <p class="text-muted text-truncate mb-0">
                          {{ user.designation }}
                        </p>
                      </div>
                      <b-dropdown
                        variant="white"
                        class="flex-shrink-0"
                        toggle-class="text-body font-size-16 p-0"
                        right
                      >
                        <template v-slot:button-content>
                          <feather
                            type="more-horizontal"
                            class="icon-xs"
                          ></feather>
                        </template>
                        <b-dropdown-item @click="edit_staff(user)">Edit</b-dropdown-item>
                        <b-dropdown-item @click="delete_staff(user)">Remove</b-dropdown-item>
                      </b-dropdown>
                      <!-- end dropdown -->
                    </div>
                  </div>
                  <!-- end card body -->
                  <div class="btn-group btn-icon" role="group">
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Profile"
                    >
                      <i class="uil uil-user"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Message"
                    >
                      <i class="uil uil-envelope-alt"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-light"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Projects"
                    >
                      <i class="uil uil-chart-pie"></i>
                    </button>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
